import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import {
  TextField,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import apiClient from "../../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import { DatePicker } from "@material-ui/pickers";
import { useStyles } from "./style.js";
import { Card } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { CardHeader } from "@material-ui/core";
import clsx from "clsx";
import * as Yup from "yup";
import { useConfirmationDialog } from "../../../../hooks/useConfirmationDialog";
import moment from "moment";
import CredentialService from "../../../../services/CredentialService";
import useValidationPDFGenerator from "../Modules/useValidationPDFGenerator";
import { isEmpty } from "lodash";
import MultiValidationOfferDropdown from "../../../Dropdowns/MultiValidationOfferDropdown";
import { useSelector } from "react-redux";

const credentialService = new CredentialService(apiClient);

const DownloadValidationSchema = Yup.object().shape({
  validationOffer: Yup.object()
    .nullable()
    .required("Must select a validation"),
  quantity: Yup.number()
    .positive("Quantity must be a positive number")
    .required("Quantity is required")
    .typeError("Quantity must be a number")
    .integer()
    .min(1, "Quantity cannot be less than 1"),
  maxUses: Yup.number()
    .positive("Max uses must be a postive number")
    .required("Max uses is required")
    .typeError("Max uses must be a number")
    .integer()
    .min(
      Yup.ref("maxUsesPerTransaction"),
      "Max uses must be more than or equal to the max uses per transaction"
    ),
  maxUsesPerTransaction: Yup.number()
    .positive("Uses per transaction must be a postive number")
    .required("Uses per transaction is required")
    .typeError("Uses per transaction must be a number")
    .integer()
    .min(1, "Uses per transaction cannot be less than 1"),
  validFrom: Yup.date()
    .required("Valid from date is required")
    .typeError("Invalid date format"),
  validUntil: Yup.date()
    .when(
      "validFrom",
      (validFromDate, schema) =>
        validFromDate &&
        schema.min(
          validFromDate,
          "Valid until date must be after valid from date"
        )
    )
    .typeError("Invalid date format")
    .required("Valid until date is required"),
});

export default function DownloadValidationsForm({
  className,
  contractHolderIDs,
}) {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { showDialog } = useConfirmationDialog();
  const { generate } = useValidationPDFGenerator();
  const [loading, setLoading] = useState(false);
  const currentUserID = useSelector((state) => state.user?.UserID);

  const {
    submitForm,
    errors,
    handleChange,
    setFieldValue,
    values,
    dirty,
  } = useFormik({
    initialValues: {
      quantity: 1,
      maxUses: 1,
      maxUsesPerTransaction: 1,
      validFrom: moment(new Date(Date.now()).setHours(0, 0, 0, 0)),
      validUntil: moment(new Date().setHours(23, 59, 59, 59)),
    },
    enableReinitialize: false,
    validationSchema: DownloadValidationSchema,
    onSubmit: handleDownload,
  });

  async function handleDownload(values) {
    const {
      validationOffer,
      quantity,
      validFrom,
      validUntil,
      maxUses,
      maxUsesPerTransaction,
    } = values;
    const response = await showDialog({
      title: "Download Confirmation",
      message: `Are you sure you want to continue downloading? This will create ${values.quantity} of '${validationOffer.name}' validations`,
      buttons: [
        { name: "Yes", color: "primary" },
        { name: "No", color: "secondary" },
      ],
    });
    if (response === "No") return;

    setLoading(true);
    try {
      const bulkResponse = await credentialService.BulkCreateValidations({
        validationOffer: validationOffer.id,
        quantity: Number(quantity),
        validFrom: moment(validFrom).format("YYYY-MM-DDTHH:mm:ssZ"),
        validUntil: moment(validUntil).format("YYYY-MM-DDTHH:mm:ssZ"),
        maxUses: Number(maxUses),
        maxUsesPerTransaction: Number(maxUsesPerTransaction),
        kind: "Physical",
        format: "QR Code",
        userID: currentUserID,
      });
      await generate(
        validationOffer.account,
        validationOffer.name,
        bulkResponse.data
      );
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to create validations", { variant: "error", tag: "FailedToCreateValidation" });
    } finally {
      setLoading(false);
    }
  }

  const offerChange = (offer) => {
    setFieldValue("validationOffer", offer);
  };

  return (
    <Card className={clsx(className, classes.root)}>
      <CardHeader className={classes.cardHeader} title="Printable Validations" />
      <CardContent className={classes.contentContainer}>
        <MultiValidationOfferDropdown
          className={clsx(classes.groupedValidationDropdown, classes.flexInput)}
          contractHolderIDs={contractHolderIDs}
          onChange={offerChange}
        />
        {errors.validationOffer && (
          <Typography className={classes.formError}>
            {errors.validationOffer}
          </Typography>
        )}
        <TextField
          className={classes.flexInput}
          label="Quantity"
          id="Quantity"
          name="quantity"
          variant="outlined"
          defaultValue={values.quantity}
          onChange={handleChange}
          error={errors && errors.quantity ? true : false}
          helperText={errors && errors.quantity}
        />
        <TextField
          className={classes.flexInput}
          label="Max Uses"
          id="Max Uses"
          name="maxUses"
          variant="outlined"
          defaultValue={values.maxUses}
          onChange={handleChange}
          error={errors && errors.maxUses ? true : false}
          helperText={errors && errors.maxUses}
        />
        <TextField
          className={classes.flexInput}
          label="Uses Per Transaction"
          id="Uses Per Transaction"
          name="maxUsesPerTransaction"
          variant="outlined"
          defaultValue={values.maxUsesPerTransaction}
          onChange={handleChange}
          error={errors && errors.maxUsesPerTransaction ? true : false}
          helperText={errors && errors.maxUsesPerTransaction}
        />
        <DatePicker
          className={classes.flexInput}
          value={values.validFrom ?? null}
          name="validFrom"
          inputVariant="outlined"
          label="Valid From"
          id="Valid From"
          onChange={(val) => setFieldValue("validFrom", val)}
          error={errors && errors.validFrom ? true : false}
          helperText={errors && errors.validFrom}
          format="MM/DD/YYYY"
        />
        <DatePicker
          className={classes.flexInput}
          value={values.validUntil ?? null}
          name="validUntil"
          inputVariant="outlined"
          label="Valid Until"
          id="Valid Until"
          onChange={(val) => setFieldValue("validUntil", val)}
          error={errors && errors.validUntil ? true : false}
          helperText={errors && errors.validUntil}
          format="MM/DD/YYYY"
        />
        <div className={classes.downloadBtnContainer}>
          {loading === true && (
            <CircularProgress className={classes.downloadProgress} />
          )}
          <Button
            className={classes.downloadBtn}
            variant="contained"
            color="primary"
            onClick={submitForm}
            disabled={loading === true || !isEmpty(errors) || !dirty}
          >
            Download
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}

DownloadValidationsForm.propTypes = {
  className: PropTypes.string,
  contractHolderIDs: PropTypes.arrayOf(PropTypes.number),
  entityID: PropTypes.string.isRequired,
};
