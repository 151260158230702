import React, { useEffect, useState } from "react";
import ThirdPartyVendorConfigurationPanel from "../../ThirdPartyVendors/CreateThirdPartyVendor";
import { useStyles } from "./style";
import ThirdpartyVendorService from "../../../services/ThirdpartyVendorService";
import ContactService from "../../../services/ContactService";
import AuthService from "../../../services/AuthService";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import clsx from "clsx";
import UserService from "../../../services/UserService";
import _ from "lodash";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";

const authService = new AuthService(apiClient);
const thirdPartyVendorService = new ThirdpartyVendorService(apiClient);
const contactService = new ContactService(apiClient);
const userService = new UserService(apiClient);

const ThirdPartyVendorConfigurationForm = ({
  integratorInfo,
  handleCreated,
  handleUpdated,
  editPermission,
  onGoBackClick
}) => {
  const classes = useStyles();
  const subscriberIDExist =
    integratorInfo?.subscriberID == undefined ||
    integratorInfo?.subscriberID == null ||
    integratorInfo?.subscriberID == ""
      ? true
      : false;
  const useCoreEntitySlice = useCoreEntityContext();

  const enqueueSnackbar = useEnqueueSnackbar();
  const entityID = useSelector(state =>useCoreEntitySlice ? state.coreEntities.ContextID : state.entities.ContextID);
  const currentUserId = useSelector(state => state.user.UserID);
  const isAdmin = useSelector(state => state?.user?.isAdmin);
  const [disabledState, setDisabled] = useState(subscriberIDExist);
  const [vendorDetails, setVendorDetails] = useState(integratorInfo);
  const revoked = integratorInfo?.activeAPICount > 0 ? '•••••••••••••••' : 'revoked'; // still mask to make the flicker of load less visible
  const [apiCredentials, setApiCredentials] = useState({
    id: subscriberIDExist ? "ID" : revoked,
    clientSecret: subscriberIDExist ? "Secret" : revoked
  });

  const handleRevoke = async subscriberID => {
    try {
      await authService.blackListSubscriber(subscriberID);
      vendorDetails.activeAPICount = 0;
      setApiCredentials({
        id: "revoked",
        clientSecret: "revoked"
      });
    } catch {
      enqueueSnackbar("Failed to revoke integrator API access.", {
        variant: "error",
        tag: "FailedToRevokeIntegratorAPIAccess"
      });
    }
  };

  const handleGeneration = async subscriberID => {
    return await authService
      .getClientAndSecretForThirdParty(subscriberID)
      .then(res => {
        vendorDetails.activeAPICount = 1;
        setApiCredentials({
          id: res.data.clientID,
          clientSecret: res.data.clientSecret
        });
      })
      .catch(err => {
        enqueueSnackbar("Failed to get ClientID and Secret.", {
          variant: "error",
          tag: "FailedToGetClientID"
        });
      });
  };

  const handleSave = async values => {
    let thirdPartyObject = {
      subscriberID:
        vendorDetails.subscriberID == "" ? "" : vendorDetails.subscriberID,
      description: values.vendorName,
      startDate: new Date(),
      endDate: new Date(),
      modifiedBy: currentUserId,
      status: true,
      activeAPICount: vendorDetails.activeAPICount,
      contacts: [
        {
          isPrimary: true,
          entityID: entityID,
          firstName: values.primaryFirstName,
          lastName: values.primaryLastName,
          contactID:
            vendorDetails.contacts[0]?.contactID == ""
              ? ""
              : vendorDetails.contacts[0]?.contactID,
          addresses: [
            {
              addressID:
                integratorInfo?.contacts[0].addresses[0].addressID == ""
                  ? ""
                  : integratorInfo?.contacts[0].addresses[0].addressID,
              addressLine1: values.primaryAddress1,
              addressLine2: values.primaryAddress2,
              state: values.primaryState,
              postalCode: values.primaryZipCode,
              city: values.primaryCity,
              type: "Work",
              isBilling: true,
              isActive: true,
              isPrimary: true
            }
          ],
          phoneNumbers: [
            {
              phoneID:
                integratorInfo?.contacts[0].addresses[0].phoneID == ""
                  ? ""
                  : integratorInfo?.contacts[0].addresses[0].phoneID,
              number: values.primaryPhone1,
              type: "Work",
              isActive: true,
              isPrimary: true
            }
          ],
          emails: [
            {
              emailID:
                integratorInfo?.contacts[0].addresses[0].emailID == ""
                  ? ""
                  : integratorInfo?.contacts[0].addresses[0].emailID,
              emailAddress: values.primaryEmail,
              type: "Work",
              isActive: true,
              isPrimary: true
            }
          ]
        },
        {
          isPrimary: false,
          entityID: entityID,
          firstName: values.devFirstName,
          lastName: values.devLastName,
          contactID:
            vendorDetails.contacts[1]?.contactID == ""
              ? ""
              : vendorDetails.contacts[1]?.contactID,
          addresses: [],
          phoneNumbers: [
            {
              phoneID:
                integratorInfo?.contacts[0].addresses[0].phoneID == ""
                  ? ""
                  : integratorInfo?.contacts[0].addresses[0].phoneID,
              number: values.devPhone1,
              type: "Work",
              isActive: true,
              isPrimary: true
            }
          ],
          emails: [
            {
              emailID:
                integratorInfo?.contacts[0].addresses[0].emailID == ""
                  ? ""
                  : integratorInfo?.contacts[0].addresses[0].emailID,
              emailAddress: values.devEmail,
              type: "Work",
              isActive: true,
              isPrimary: true
            }
          ]
        }
      ]
    };

    if (values.primaryPhone2) {
      thirdPartyObject.contacts[0].phoneNumbers.push({
        number: values.primaryPhone2,
        type: "Work",
        isActive: true,
        isPrimary: false
      });
    }

    if (values.devPhone2) {
      thirdPartyObject.contacts[1].phoneNumbers.push({
        number: values.devPhone2,
        type: "Work",
        isActive: true,
        isPrimary: false
      });
    }

    if (
      thirdPartyObject.subscriberID != "" &&
      thirdPartyObject.subscriberID != undefined &&
      thirdPartyObject.subscriberID != null
    ) {
      let err = false;

      contactService
        .updateContact(thirdPartyObject.contacts[0])
        .then(primaryRes => {
          thirdPartyObject.contacts[0] = primaryRes.data;
        })
        .catch(() => {
          err = true;
        });

      contactService
        .updateContact(thirdPartyObject.contacts[1])
        .then(primaryRes => {
          thirdPartyObject.contacts[1] = primaryRes.data;
        })
        .catch(() => {
          err = true;
        });

      thirdPartyVendorService
        .updateThirdParty(thirdPartyObject)
        .then(primaryRes => {
          thirdPartyObject.description = primaryRes.data.description;
        })
        .catch(() => {
          err = true;
        });

      if (!err) {
        enqueueSnackbar("Successfully updated third party vendor", {
          variant: "success"
        });
      } else {
        enqueueSnackbar("Failed to update third party vendor", {
          variant: "error",
          tag: "FailedToUpdateThirdPartyVendor"
        });
      }

      setVendorDetails(thirdPartyObject);
      handleUpdated(thirdPartyObject);
    } else {
      try {
        let result = await contactService.createContact(
          thirdPartyObject.contacts[0]
        );
        thirdPartyObject.contacts[0] = result.data;
      } catch {
        enqueueSnackbar("Failed to create primary contact", {
          variant: "error",
          tag: "FailedToCreatePrimaryContact"
        });
      }

      try {
        let result = await contactService.createContact(
          thirdPartyObject.contacts[1]
        );
        thirdPartyObject.contacts[1] = result.data;
      } catch {
        enqueueSnackbar("Failed to create developer contact", {
          variant: "error",
          tag: "FailedToCreateDeveloperContact"
        });
      }

      try {
        let result = await thirdPartyVendorService.createThirdParty(
          thirdPartyObject
        );
        thirdPartyObject.subscriberID = result.data.subscriberID;
        handleGeneration(thirdPartyObject.subscriberID);
        setVendorDetails(thirdPartyObject);

        enqueueSnackbar("Successfully created third party vendor", {
          variant: "success"
        });

        handleCreated(thirdPartyObject);
        setDisabled(false);
      } catch {
        enqueueSnackbar("Failed to create developer contact", {
          variant: "error",
          tag: "FailedToCreateDeveloperContact"
        });
      }
    }
  };

  useEffect(() => {
    if (!_.isEmpty(integratorInfo?.subscriberID) && integratorInfo.activeAPICount > 0) {
      userService.GetIntegratorCredentials(integratorInfo.subscriberID)
        .then((result) => {
          if (_.isEmpty(result.data?.clientID)) {
            return;
          }
          vendorDetails.activeAPICount = 1;
          setApiCredentials({
            id: result.data.clientID,
            clientSecret: result.data.clientSecret
          });
        })
        .catch((error) => {
          console.log("Error during integrator credential GET", error);
          enqueueSnackbar("Failed to get ClientID and Secret.",
           {
            variant: "error",
            tag: "FailedToGetClientID"
           });
        });
    }
  }, [integratorInfo.subscriberID, vendorDetails]);

  if (!isAdmin) return <></>;

  return (
    <div>
      <Button
        className={clsx("go-back-btn", classes.goBackBtn)}
        variant="text"
        startIcon={<ArrowBackIcon />}
        onClick={onGoBackClick}>
        Go Back
      </Button>
      <ThirdPartyVendorConfigurationPanel
        vendorDetails={vendorDetails}
        apiCredentials={apiCredentials}
        handleSave={handleSave}
        disabledState={disabledState}
        handleGeneration={handleGeneration}
        handleRevoke={handleRevoke}
        editPermission={editPermission}
      />
    </div>
  );
};
ThirdPartyVendorConfigurationForm.defaultProps = {
  onGoBackClick: () => {}
};
export default ThirdPartyVendorConfigurationForm;
