import React, { useEffect, useCallback, useState } from "react";
import { MenuItem, TextField } from "@material-ui/core";
import ValidationService from "../../../../services/ValidationAccountService";
import apiClient from "../../../../auth/apiClient";
import { useSelector } from "react-redux";
import clsx from "clsx";
import {useCoreEntityContext} from "../../../../hooks/useCoreEntitySlice";

const validationService = new ValidationService(apiClient);

export default function RateSwitchDropdown({
  className,
  errors,
  value,
  onChange,
  disabled,
  onBlur,
}) {
  const useCoreEntitySlice = useCoreEntityContext();

  const facilityID = useSelector((state) => useCoreEntitySlice ? state.coreEntities?.ContextID :  state.entities?.ContextID);
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const [availableRates, setAvailableRates] = useState();

  useEffect(() => {
    getAvailableRates();
  }, [scopeAwareFacilityID]);

  const getAvailableRates = useCallback(async () => {
    try {
      const response = await validationService.getRateSwitchRates(scopeAwareFacilityID);
      setAvailableRates(response.data);
    } catch (err) {
      console.error(err);
      setAvailableRates([]);
    }
  }, [scopeAwareFacilityID]);

  return (
    <TextField
      id="rate-switch"
      className={clsx("rateSwitch", className)}
      name="rate"
      label="Rate"
      select
      onChange={onChange}
      onBlur={onBlur}
      defaultValue={value ?? ""}
      value={value ?? ""}
      error={errors && errors.type ? true : false}
      helperText={errors && errors.type}
      disabled={disabled}
      SelectProps={{
        SelectDisplayProps: { "data-testid": "rate-type" }
      }}
      InputProps={{
        readOnly: Boolean(disabled),
        "aria-readonly": Boolean(disabled),
        disabled: Boolean(disabled),
        "aria-label": "Rate Type",
      }}
    >
      {availableRates?.map((rate, index) => {
        return (
          <MenuItem key={index} value={rate.rateBlobID}>
            {rate.name}
          </MenuItem>
        );
      })}
    </TextField>
  );
}
